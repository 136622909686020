import React from 'react';
import useragent from 'useragent';
import Cookies from 'js-cookie';
import { isRecommendedBrowser } from '../../utils/utils';
import Modal from './core/Modal';
import Button from './core/Button';

class BrowserWarningModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      browser: null,
    };

    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    const dismissed = Cookies.get('browserWarningDismissed');

    if (!dismissed && navigator) {
      const browser = useragent.parse(navigator.userAgent);
      if (!isRecommendedBrowser(browser)) {
        this.setState({
          open: true,
          browser,
        });
      }
    }
  }

  handleClose() {
    this.setState({ open: false });
    Cookies.set('browserWarningDismissed', true);
  }

  render() {
    const { open, browser } = this.state;

    const browserName = browser && browser.family ? browser.family : '';

    return (
      <Modal isOpen={open} onClose={this.handleClose} title="Warning" size="small">
        <p className="text-center">
          Your browser, <strong>{browserName}</strong>, is not officially recommended for use with Salsa. You can
          continue, but certain functions may not work as intended.
        </p>

        <p className="text-center">Recommended browsers are Chrome and Safari.</p>

        <div className="text-center">
          <Button variant="primary" onClick={this.handleClose}>
            Dismiss
          </Button>
        </div>
      </Modal>
    );
  }
}

export default BrowserWarningModal;
