/** @jsxImportSource theme-ui */
import _ from 'lodash';
import { AnimateSharedLayout, AnimatePresence, motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LogoPage from '../templates/LogoPage';
import Checkbox from '../core/Checkbox'; // LEGACY
import { Button, Error, Box, Flex, Card, Heading, Paragraph, Link } from '../atoms';
import { TextField } from '../molecules';
import { isEmail } from '../../../utils/inputValidators';
import { logIn } from '../../../actions/actions';
import { Router } from '../../../routes';

const Login = ({ desiredPath }) => {
  const dispatch = useDispatch();
  const accessToken = useSelector((store) => _.get(store, 'userData.user.accessToken'));
  const accessError = useSelector((store) => _.get(store, 'userData.errorLogin'));

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const [errors, setErrors] = useState({ email: null, password: null, global: null });
  const [isLoading, setIsLoading] = useState(false);

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const totalErrors = {};
    if (!password.length || !email.length) {
      totalErrors.global = 'Please, fill out all fields.';
    } else if (!isEmail.validate(email)) {
      totalErrors.email = isEmail.message;
    }
    setErrors(totalErrors);
    if (Object.keys(totalErrors).length) {
      return;
    }
    dispatch(logIn({ email, password, remember }));
  };

  // Redirect upon successful login
  useEffect(() => {
    if (accessToken) {
      if (desiredPath && desiredPath.length) {
        Router.push(desiredPath);
      } else {
        Router.push('/admin/events');
      }
    }
  }, [accessToken]);

  // Show error on failed login
  useEffect(() => {
    if (accessError && accessError.length) {
      const { message } = accessError[0];
      // const key = message.toLowerCase().includes('password') ? 'password' : 'global';
      const key = 'global';
      setErrors({ [key]: message });
    }
  }, [accessError]);

  // Stop loading on error
  useEffect(() => {
    if (Object.entries(errors).map((e) => e).length) {
      setIsLoading(false);
    }
  }, [errors]);

  return (
    <LogoPage>
      <Box as="form" onSubmit={handleSubmit}>
        <motion.div layout>
          <Box sx={{ textAlign: ['center', 'left'] }}>
            <Heading as="h1" sx={{ fontSize: 5 }} mb={3}>
              Login to Salsa
            </Heading>

            <Paragraph mb={4}>
              Don&rsquo;t have an account?&nbsp;
              <Link variant="primary" href="/signup">
                Sign up.
              </Link>
            </Paragraph>
          </Box>
        </motion.div>

        <AnimatePresence exitBeforeEnter>
          {errors.global && <Error key={errors.global}>{errors.global}</Error>}
        </AnimatePresence>

        <motion.div layout>
          <TextField label="Email" value={email} onChange={(value) => setEmail(value)} errorMessage={errors.email} />

          <TextField
            label="Password"
            value={password}
            onChange={(value) => setPassword(value)}
            type="password"
            errorMessage={errors.password}
          />

          <Flex
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            mb={4}
          >
            <Checkbox
              id="remember"
              label="Keep me logged in"
              checked={remember}
              onCheck={(checked) => setRemember(checked)}
            />

            <Link variant="primary" href="/resetpassword">
              Forgot your password?
            </Link>
          </Flex>

          <Flex sx={{ justifyContent: 'center' }}>
            <Button type="submit" variant="primary" isLoading={isLoading}>
              Login
            </Button>
          </Flex>
        </motion.div>
      </Box>
    </LogoPage>
  );
};

export default Login;
