const validator = require('validator');

exports.isURL = {
  validate: (value) => validator.isURL(value, { protocols: ['http', 'https'], require_protocol: true }),
  message: 'This URL is invalid.',
};

exports.isHTTPS = {
  validate: (value) => validator.isURL(value, { protocols: ['https'], require_protocol: true }),
  message: 'This URL is invalid.',
};

exports.isEmail = {
  validate: (value) => validator.isEmail(value),
  message: 'This email is invalid.',
};

exports.isPhone = {
  validate: (value) => validator.isMobilePhone(value, 'any'),
  message: 'This phone number is invalid.',
};

exports.isSubdomain = {
  validate: (value) => /^\w([a-zA-Z0-9-])+\w$/.test(value),
  message: 'Invalid subdomain. Valid characters are alphanumerical and - (except at the start or end)',
};

exports.isWords = {
  validate: (value) => validator.matches(value, /^[a-z0-9'\-. ]+$/i),
  message: 'Invalid input',
};
