import { Box, Flex, Paragraph, Button, Radio } from '../atoms';

const RadioRow = (props) => {
  const { name, value, onPress } = props;

  const handlePress = () => {
    onPress(value);
  };

  return (
    <Flex sx={{ alignItems: 'center', width: '100%' }}>
      <Radio value={value} aria-label={value} />
      <Button onPress={handlePress} variant="listItem">
        <Flex sx={{ textAlign: 'left', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <Box sx={{ pr: 1 }}>
            <Paragraph variant="truncated" sx={{ fontSize: 2 }}>
              {name}
            </Paragraph>
          </Box>
        </Flex>
      </Button>
    </Flex>
  );
};

export default RadioRow;
