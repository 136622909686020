/** @jsxImportSource theme-ui */
import { Container, Flex, Image, Card } from 'theme-ui';
import { motion, AnimateSharedLayout } from 'framer-motion';

const LogoPage = ({ children, isWide }) => {
  return (
    <Container variant={!isWide ? 'small' : ''}>
      <Flex sx={{ justifyContent: 'center' }} pt={6} mb={6}>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <Image src="/static/salsa-racetrack-color.svg" sx={{ height: 84 }} />
        </motion.div>
      </Flex>

      <AnimateSharedLayout>
        <motion.div
          initial={{ opacity: 0, y: 48 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ type: 'spring', mass: 1.4, damping: 15 }}
          layout
        >
          <Card variant="large">{children}</Card>
        </motion.div>
      </AnimateSharedLayout>
    </Container>
  );
};

export default LogoPage;
