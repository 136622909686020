import React from 'react';
import Head from 'next/head';
import NoSSR from '@mpth/react-no-ssr';
import nextCookie from 'next-cookies';
import _ from 'lodash';
import { getFavIcon } from '../src/scripts/utils/getFavIcon';
import Login from '../src/scripts/components/webapp/pages/Login';
import BrowserWarningModal from '../src/scripts/components/webapp/BrowserWarningModal';
import '../src/styles/webapp/main.styl';

class LoginPage extends React.Component {
  static async getInitialProps({ isServer, query, req, res }) {
    if (isServer) {
      let { operator } = nextCookie({ req });
      const keys = ['accessToken', 'operatorId', 'organizationId', 'email', 'newUser', 'superUser'];
      operator = _.pickBy(_.pick(operator, keys), (val) => !_.isUndefined(val));
      if (_.keys(operator).length === keys.length) {
        let loginPath = query.path;
        if (!loginPath || !loginPath.length) {
          loginPath = '/admin/events';
        }
        res.writeHead(302, { Location: loginPath });
        res.end();
      }
    }

    return {
      accountCreated: query.success === 'true',
      desiredPath: query.path,
      isServer,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.documentElement.classList.add('no-sidebar');
    document.documentElement.classList.remove('preload');
  }

  componentWillUnmount() {
    document.documentElement.classList.remove('no-sidebar');
  }

  render() {
    const { accountCreated, isServer, desiredPath } = this.props;
    return (
      <NoSSR>
        <Head>
          <title>Login | Salsa Booth</title>
          {getFavIcon()}
        </Head>

        <BrowserWarningModal />

        <Login isServer={isServer} accountCreated={accountCreated} desiredPath={desiredPath} />
      </NoSSR>
    );
  }
}

export default LoginPage;
