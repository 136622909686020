/*
 * Action Types
 */

import { toast } from 'react-toastify';

const toastDismissTime = 2000;

export const INCREMENT_SHARE_CLICKS = 'INCREMENT_SHARE_CLICKS';
export function incrementShareClicks(body) {
  return {
    type: INCREMENT_SHARE_CLICKS,
    body,
  };
}

export const REQUEST_EVENT_WITH_PASSWORD = 'REQUEST_EVENT_WITH_PASSWORD';
export function requestEventAccessWithPassword(id, password, idType = 'event') {
  return {
    type: REQUEST_EVENT_WITH_PASSWORD,
    id,
    password,
    idType,
  };
}

export const PASSWORD_TOKEN_REQUEST = 'PASSWORD_TOKEN_REQUEST';
export function passwordTokenRequest() {
  return {
    type: PASSWORD_TOKEN_REQUEST,
  };
}

export const PASSWORD_TOKEN_FAILURE = 'PASSWORD_TOKEN_FAILURE';
export function passwordTokenFailure(body) {
  return {
    type: PASSWORD_TOKEN_FAILURE,
    data: body,
    error: body,
  };
}

export const PASSWORD_TOKEN_SUCCESS = 'PASSWORD_TOKEN_SUCCESS';
export function passwordTokenSuccess(body) {
  return {
    type: PASSWORD_TOKEN_SUCCESS,
    data: body,
  };
}

export const EVENT_NEEDS_PASSWORD = 'EVENT_NEEDS_PASSWORD';
export function requirePassword(eventId, error) {
  return {
    type: EVENT_NEEDS_PASSWORD,
    eventId,
    error,
  };
}

export const EVENT_PASSWORD_REQUEST = 'EVENT_PASSWORD_REQUEST';
export function requirePasswordRequest() {
  return {
    type: EVENT_PASSWORD_REQUEST,
  };
}

export const PASSWORD_NOT_REQUIRED = 'PASSWORD_NOT_REQUIRED';
export function passwordNotRequired(eventId) {
  return {
    type: PASSWORD_NOT_REQUIRED,
    eventId,
  };
}

export const REQUEST_GET_EVENT = 'REQUEST_GET_EVENT';
export function requestEvent(eventId, isServer, subdomain) {
  return {
    type: REQUEST_GET_EVENT,
    eventId,
    isServer,
    subdomain,
  };
}

export const RETRIEVED_GET_EVENT = 'RECEIVED_GET_EVENT';
export function retrievedEvent(event) {
  return {
    type: RETRIEVED_GET_EVENT,
    payload: event,
  };
}

export const REQUEST_GET_EVENT_ANALYTICS = 'REQUEST_GET_EVENT_ANALYTICS';
export function requestEventAnalytics(eventId, analyticsToken, subdomain) {
  return {
    type: REQUEST_GET_EVENT_ANALYTICS,
    eventId,
    analyticsToken,
    subdomain,
  };
}

export const RETRIEVED_GET_EVENT_ANALYTICS = 'RETRIEVED_GET_EVENT_ANALYTICS';
export function retrievedEventAnalytics(data) {
  return {
    type: RETRIEVED_GET_EVENT_ANALYTICS,
    eventId: data.eventId,
    payload: data.analytics,
  };
}

export const REQUEST_GET_EVENT_TOP_MEDIAS = 'REQUEST_GET_EVENT_TOP_MEDIAS';
export function requestEventTopMedias(eventId, analyticsToken, sortParam) {
  return {
    type: REQUEST_GET_EVENT_TOP_MEDIAS,
    eventId,
    sortParam,
    analyticsToken,
  };
}

export const RETRIEVED_GET_EVENT_TOP_MEDIAS = 'RETRIEVED_GET_EVENT_TOP_MEDIAS';
export function retrievedEventTopMedias(data) {
  return {
    type: RETRIEVED_GET_EVENT_TOP_MEDIAS,
    payload: data,
  };
}

export const REQUEST_GET_MEDIAS = 'REQUEST_GET_MEDIAS';
export function requestMedias(eventId, isServer, subdomain, dateFrom) {
  return {
    type: REQUEST_GET_MEDIAS,
    eventId,
    isServer,
    subdomain,
    dateFrom,
  };
}

export const REQUEST_GET_NEW_MEDIAS = 'REQUEST_GET_NEW_MEDIAS';
export function requestNewMedias(eventId, dateAfter, isServer, subdomain) {
  return {
    type: REQUEST_GET_NEW_MEDIAS,
    eventId,
    isServer,
    subdomain,
    dateAfter,
  };
}

export const CLEAR_MEDIAS = 'CLEAR_MEDIAS';
export function clearMedias() {
  return {
    type: CLEAR_MEDIAS,
  };
}

export const SET_MEDIA_FILTERS = 'SET_MEDIA_FILTERS';
export function setFilters(filters) {
  return {
    type: SET_MEDIA_FILTERS,
    payload: filters,
  };
}

export const CLEAR_MEDIA_FILTERS = 'CLEAR_MEDIA_FILTERS';
export function clearFilters() {
  return {
    type: CLEAR_MEDIA_FILTERS,
    eventId,
    filters,
  };
}

export const RETRIEVED_GET_MEDIAS = 'RECEIVED_GET_MEDIAS';
export function retrievedMedias(medias) {
  return {
    type: RETRIEVED_GET_MEDIAS,
    payload: medias,
  };
}

export const UPDATE_EVENT_MEDIA_INDEX = 'UPDATE_EVENT_MEDIA_INDEX';
export function updateEventMediaIndex(eventId, index) {
  return {
    type: UPDATE_EVENT_MEDIA_INDEX,
    eventId,
    payload: index,
  };
}

export const UPDATE_NEW_MEDIAS = 'UPDATE_NEW_MEDIAS';
export function updateNewMedias(eventId, index) {
  return {
    type: UPDATE_NEW_MEDIAS,
    eventId,
    payload: index,
  };
}

export const RETRIEVED_GET_TEMPLATES = 'RECEIVED_GET_TEMPLATES';
export function retrievedTemplate(medias, templateId) {
  return {
    type: RETRIEVED_GET_TEMPLATES,
    payload: medias,
    templateId,
  };
}

export const REQUEST_GET_MEDIA = 'REQUEST_GET_MEDIA';
export function requestMedia(mediaId, eventId, subdomain) {
  return {
    type: REQUEST_GET_MEDIA,
    mediaId,
    eventId,
    subdomain,
  };
}

export const RETRIEVED_GET_MEDIA = 'RECEIVED_GET_MEDIA';

export const ACTIVE_PHOTO = 'ACTIVE_PHOTO';
export function activePhoto(id) {
  return {
    type: ACTIVE_PHOTO,
    id,
  };
}

export const LIST_PORTFOLIO_EVENTS = 'LIST_PORTFOLIO_EVENTS';
export function listPortfolioEvents(id, data, level = 1) {
  return {
    type: LIST_PORTFOLIO_EVENTS,
    data,
    level,
    id,
  };
}

export const LIST_PORTFOLIO_EVENTS_REQUEST = 'LIST_PORTFOLIO_EVENTS_REQUEST';
export function listPortfolioEventsRequest() {
  return {
    type: LIST_PORTFOLIO_EVENTS_REQUEST,
  };
}

export const LIST_PORTFOLIO_EVENTS_RESET = 'LIST_PORTFOLIO_EVENTS_RESET';
export function listPortfolioEventsReset() {
  return {
    type: LIST_PORTFOLIO_EVENTS_RESET,
  };
}

export const LIST_PORTFOLIO_EVENTS_SUCCESS = 'LIST_PORTFOLIO_EVENTS_SUCCESS';
export function listPortfolioEventsSuccess(data) {
  return {
    type: LIST_PORTFOLIO_EVENTS_SUCCESS,
    data,
  };
}

export const LIST_PORTFOLIO_EVENTS_FAILURE = 'LIST_PORTFOLIO_EVENTS_FAILURE';
export function listPortfolioEventsFailure(error) {
  return {
    type: LIST_PORTFOLIO_EVENTS_FAILURE,
    error,
  };
}

export const GET_PORTFOLIO_SETTINGS = 'GET_PORTFOLIO_SETTINGS';
export function getPortfolioSettings(id, passwordAccessToken) {
  return {
    type: GET_PORTFOLIO_SETTINGS,
    id,
    passwordAccessToken,
  };
}

export const GET_PORTFOLIO_SETTINGS_REQUEST = 'GET_PORTFOLIO_SETTINGS_REQUEST';
export function getPortfolioSettingsRequest(data) {
  return {
    type: GET_PORTFOLIO_SETTINGS_REQUEST,
  };
}

export const GET_PORTFOLIO_SETTINGS_SUCCESS = 'GET_PORTFOLIO_SETTINGS_SUCCESS';
export function getPortfolioSettingsSuccess(data) {
  return {
    type: GET_PORTFOLIO_SETTINGS_SUCCESS,
    data,
  };
}

export const GET_PORTFOLIO_SETTINGS_FAILURE = 'GET_PORTFOLIO_SETTINGS_FAILURE';
export function getPortfolioSettingsFailure(error) {
  return {
    type: GET_PORTFOLIO_SETTINGS_FAILURE,
    error,
  };
}

export const START_FETCH = 'START_FETCHING';
export function startFetch() {
  return {
    type: START_FETCH,
  };
}

export const END_FETCH = 'END_FETCH';
export function endFetch() {
  return {
    type: END_FETCH,
  };
}

export const ACTIVE_EVENT = 'ACTIVE_EVENT';
export function activeEvent(event) {
  return {
    type: ACTIVE_EVENT,
    payload: event,
  };
}

export const ACTIVE_MEDIA = 'ACTIVE_MEDIA';
export function activeMedia(mediaId) {
  return {
    type: ACTIVE_MEDIA,
    payload: mediaId,
  };
}

export const PAGE_TYPE = 'PAGE_TYPE';
export function pageType(type) {
  return {
    type: PAGE_TYPE,
    pageType: type,
  };
}

export const UPDATE_EVENT_MEDIAS = 'UPDATE_EVENT_MEDIAS';
export function updateEventMedias(eventId, mediaIds) {
  return {
    type: UPDATE_EVENT_MEDIAS,
    eventId,
    mediaIds,
  };
}

export const UPDATE_EVENT_HAS_MORE = 'UPDATE_EVENT_HAS_MORE';
export function eventHasNoMoreMedia(eventId) {
  return {
    type: UPDATE_EVENT_HAS_MORE,
    eventId,
    value: false,
  };
}

export const UPDATE_EVENT_CLEAR_MEDIAS = 'UPDATE_EVENT_CLEAR_MEDIAS';
export function updateEventClearMedias(eventId) {
  return {
    type: UPDATE_EVENT_CLEAR_MEDIAS,
    eventId,
  };
}

export const MEDIA_NOT_FOUND = 'MEDIA_NOT_FOUND';
export function mediaNotFound(value) {
  return {
    type: MEDIA_NOT_FOUND,
    value,
  };
}

export const EVENT_NOT_FOUND = 'EVENT_NOT_FOUND';
export function eventNotFound(value) {
  return {
    type: EVENT_NOT_FOUND,
    value,
    error: 'EVENT_NOT_FOUND',
  };
}

export const DOWNLOAD_LINK = 'DOWNLOAD_LINK';
export function updateDownloadLink(value) {
  return {
    type: DOWNLOAD_LINK,
    value,
  };
}

export const SHARE_IMAGE = 'SHARE_IMAGE';
export function updateShareImage(value) {
  return {
    type: SHARE_IMAGE,
    value,
  };
}

export const SHARE_PHOTO = 'SHARE_PHOTO';
export function sharePhotoByEmail(email, eventId, mediaId) {
  return {
    type: SHARE_PHOTO,
    email,
    eventId,
    mediaId,
  };
}

export const SHARE_PHOTO_REQUEST = 'SHARE_PHOTO_REQUEST';
export function sharingPhotoRequest() {
  return {
    type: SHARE_PHOTO_REQUEST,
  };
}

export const SHARE_PHOTO_SUCCESS = 'SHARE_PHOTO_SUCCESS';
export function sharingPhotoSuccess() {
  return {
    type: SHARE_PHOTO_SUCCESS,
  };
}

export const SHARE_PHOTO_FAILURE = 'SHARE_PHOTO_FAILURE';
export function sharingPhotoFailure() {
  return {
    type: SHARE_PHOTO_FAILURE,
  };
}

export const LOADING_MEDIA = 'LOADING_MEDIA';
export function loadingMedia(value) {
  return {
    type: LOADING_MEDIA,
    value,
  };
}

export const LOADING_MEDIAS = 'LOADING_MEDIAS';
export function loadingMedias(value) {
  return {
    type: LOADING_MEDIAS,
    value,
  };
}

export const FETCH_PLANS = 'FETCH_PLANS';
export function fetchPlans(env) {
  return {
    type: FETCH_PLANS,
    env,
  };
}

export const FETCH_PLANS_REQUEST = 'FETCH_PLANS_REQUEST';
export function fetchPlansRequest() {
  return {
    type: FETCH_PLANS_REQUEST,
  };
}

export const FETCH_PLANS_SUCCESS = 'FETCH_PLANS_SUCCESS';
export function fetchPlansSuccess(plans) {
  return {
    type: FETCH_PLANS_SUCCESS,
    plans,
  };
}

export const FETCH_PLANS_FAILURE = 'FETCH_PLANS_FAILURE';
export function fetchPlansFailure() {
  return {
    type: FETCH_PLANS_FAILURE,
  };
}

export const FETCH_DEVICES = 'FETCH_DEVICES';
export function fetchDevices(accessToken) {
  return {
    type: FETCH_DEVICES,
    accessToken,
  };
}

export const FETCH_DEVICES_REQUEST = 'FETCH_DEVICES_REQUEST';
export function fetchDevicesRequest() {
  return {
    type: FETCH_DEVICES_REQUEST,
  };
}

export const FETCH_DEVICES_SUCCESS = 'FETCH_DEVICES_SUCCESS';
export function fetchDevicesSuccess(data) {
  return {
    type: FETCH_DEVICES_SUCCESS,
    data,
  };
}

export const FETCH_DEVICES_FAILURE = 'FETCH_DEVICES_FAILURE';
export function fetchDevicesFailure() {
  return {
    type: FETCH_DEVICES_FAILURE,
  };
}

export const REMOVE_DEVICE = 'REMOVE_DEVICE';
export function removeDevice(accessToken, deviceId) {
  return {
    type: REMOVE_DEVICE,
    accessToken,
    deviceId,
  };
}

export const REMOVE_DEVICE_REQUEST = 'REMOVE_DEVICE_REQUEST';
export function removeDeviceRequest(deviceId) {
  return {
    type: REMOVE_DEVICE_REQUEST,
  };
}

export const REMOVE_DEVICE_SUCCESS = 'REMOVE_DEVICE_SUCCESS';
export function removeDeviceSuccess(data) {
  toast.success('Device removed successfully.', { autoClose: toastDismissTime });
  return {
    type: REMOVE_DEVICE_SUCCESS,
    data,
  };
}

export const REMOVE_DEVICE_FAILURE = 'REMOVE_DEVICE_FAILURE';
export function removeDeviceFailure() {
  toast.error('There was an error removing the device.', { autoClose: false });
  return {
    type: REMOVE_DEVICE_FAILURE,
  };
}

export const FETCH_PROFILE = 'FETCH_PROFILE';
export function fetchProfile(organizationId, accessToken, reRender) {
  return {
    type: FETCH_PROFILE,
    organizationId,
    accessToken,
    reRender,
  };
}

export const FETCH_PROFILE_REQUEST = 'FETCH_PROFILE_REQUEST';
export function fetchProfileRequest(reRender) {
  return {
    type: FETCH_PROFILE_REQUEST,
    reRender,
  };
}

export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export function fetchProfileSuccess(profile, reRender) {
  return {
    type: FETCH_PROFILE_SUCCESS,
    profile,
    reRender,
  };
}

export const FETCH_PROFILE_FAILURE = 'FETCH_PROFILE_FAILURE';
export function fetchProfileFailure(reRender, error) {
  return {
    type: FETCH_PROFILE_FAILURE,
    reRender,
    error,
  };
}

export const CURRENT_PLAN = 'CURRENT_PLAN';
export function setCurrentPlan(plan) {
  return {
    type: CURRENT_PLAN,
    plan,
  };
}

export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export function updateOrganization(organizationId, requestBody, accessToken) {
  return {
    type: UPDATE_ORGANIZATION,
    organizationId,
    requestBody,
    accessToken,
  };
}

export const UPDATE_ORGANIZATION_REQUEST = 'UPDATE_ORGANIZATION_REQUEST';
export function updateOrganizationRequest() {
  return {
    type: UPDATE_ORGANIZATION_REQUEST,
  };
}

export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export function updateOrganizationSuccess(organization) {
  toast.success('Account updated.', { autoClose: toastDismissTime });
  return {
    type: UPDATE_ORGANIZATION_SUCCESS,
    organization,
  };
}

export const UPDATE_ORGANIZATION_FAILURE = 'UPDATE_ORGANIZATION_FAILURE';
export function updateOrganizationFailure(response) {
  toast.error(response, { autoClose: false });
  return {
    type: UPDATE_ORGANIZATION_FAILURE,
    response,
  };
}

export const RESET_ORGANIZATION_UPDATE = 'RESET_ORGANIZATION_UPDATE';
export function resetUpdateOrganizationStore() {
  return {
    type: RESET_ORGANIZATION_UPDATE,
  };
}

export const DEFAULT_ORGANIZATION_UPDATE = 'DEFAULT_ORGANIZATION_UPDATE';
export function updateOrganizationDefault() {
  return {
    type: DEFAULT_ORGANIZATION_UPDATE,
  };
}

export const FETCH_INVOICES = 'FETCH_INVOICES';
export function fetchInvoices(organizationId) {
  return {
    type: FETCH_INVOICES,
    organizationId,
  };
}

export const FETCH_INVOICES_REQUEST = 'FETCH_INVOICES_REQUEST';
export function fetchInvoicesRequest() {
  return {
    type: FETCH_INVOICES_REQUEST,
  };
}

export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS';
export function fetchInvoicesSuccess(invoices) {
  return {
    type: FETCH_INVOICES_SUCCESS,
    invoices,
  };
}

export const FETCH_INVOICES_FAILURE = 'FETCH_INVOICES_FAILURE';
export function fetchInvoicesFailure() {
  return {
    type: FETCH_INVOICES_FAILURE,
  };
}

export const CURRENT_DEVICE_QUANTITY = 'CURRENT_DEVICE_QUANTITY';
export function setCurrentDeviceQuantity(plan) {
  return {
    type: CURRENT_DEVICE_QUANTITY,
    plan,
  };
}

// will include all of the requests done in /admin (AccountSettings)
export const ACCOUNT_FETCH_REQUEST = 'ACCOUNT_FETCH_REQUEST';
export function accountFetchRequest() {
  return {
    type: ACCOUNT_FETCH_REQUEST,
  };
}

export const ACCOUNT_FETCH_FINISHED = 'ACCOUNT_FETCH_FINISHED';
export function accountFetchFinished() {
  return {
    type: ACCOUNT_FETCH_FINISHED,
  };
}

export const SIGN_UP = 'SIGN_UP';
export function signUp(user) {
  return {
    type: SIGN_UP,
    user,
  };
}

export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export function signUpRequest() {
  return {
    type: SIGN_UP_REQUEST,
  };
}

export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export function signUpSuccess(user) {
  return {
    type: SIGN_UP_SUCCESS,
    user,
  };
}

export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';
export function signUpFailure(error) {
  return {
    type: SIGN_UP_FAILURE,
    error,
  };
}

export const LOG_IN = 'LOG_IN';
export function logIn(user) {
  return {
    type: LOG_IN,
    user,
  };
}

export const LOG_IN_REQUEST = 'LOG_IN_REQUEST';
export function logInRequest() {
  return {
    type: LOG_IN_REQUEST,
  };
}
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export function logInSuccess(user) {
  return {
    type: LOG_IN_SUCCESS,
    user,
  };
}
export const LOG_IN_FAILURE = 'LOG_IN_FAILURE';
export function logInFailure() {
  return {
    type: LOG_IN_FAILURE,
  };
}
export const LOG_IN_INCORRECT = 'LOG_IN_INCORRECT';
export function logInIncorrect(error) {
  return {
    type: LOG_IN_INCORRECT,
    error,
  };
}

export const RESET_PASSWORD_INITIATE = 'RESET_PASSWORD_INITIATE';
export function resetPasswordInitiate({ email }) {
  return {
    type: RESET_PASSWORD_INITIATE,
    email,
  };
}

export const RESET_PASSWORD_INITIATE_REQUEST = 'RESET_PASSWORD_INITIATE_REQUEST';
export function resetPasswordInitiateRequest() {
  return {
    type: RESET_PASSWORD_INITIATE_REQUEST,
  };
}

export const RESET_PASSWORD_INITIATE_SUCCESS = 'RESET_PASSWORD_INITIATE_SUCCESS';
export function resetPasswordInitiateSuccess({ email, isEmailInvalid }) {
  return {
    type: RESET_PASSWORD_INITIATE_SUCCESS,
    email,
    isEmailInvalid,
  };
}

export const RESET_PASSWORD_INITIATE_FAILURE = 'RESET_PASSWORD_INITIATE_FAILURE';
export function resetPasswordInitiateFailure() {
  return {
    type: RESET_PASSWORD_INITIATE_FAILURE,
  };
}

export const RESET_PASSWORD_VALIDATE = 'RESET_PASSWORD_VALIDATE';
export function resetPasswordValidate({ token }) {
  return {
    type: RESET_PASSWORD_VALIDATE,
    token,
  };
}

export const RESET_PASSWORD_VALIDATE_REQUEST = 'RESET_PASSWORD_VALIDATE_REQUEST';
export function resetPasswordValidateRequest() {
  return {
    type: RESET_PASSWORD_VALIDATE_REQUEST,
  };
}

export const RESET_PASSWORD_VALIDATE_SUCCESS = 'RESET_PASSWORD_VALIDATE_SUCCESS';
export function resetPasswordValidateSuccess({ token, isTokenInvalid, isTokenExpired }) {
  return {
    type: RESET_PASSWORD_VALIDATE_SUCCESS,
    token,
    isTokenInvalid,
    isTokenExpired,
  };
}

export const RESET_PASSWORD_VALIDATE_FAILURE = 'RESET_PASSWORD_VALIDATE_FAILURE';
export function resetPasswordValidateFailure() {
  return {
    type: RESET_PASSWORD_VALIDATE_FAILURE,
  };
}

export const RESET_PASSWORD_FINALLY = 'RESET_PASSWORD_FINALLY';
export function resetPasswordFinally({ token, newPassword }) {
  return {
    type: RESET_PASSWORD_FINALLY,
    token,
    newPassword,
  };
}

export const RESET_PASSWORD_FINALLY_REQUEST = 'RESET_PASSWORD_FINALLY_REQUEST';
export function resetPasswordFinallyRequest() {
  return {
    type: RESET_PASSWORD_FINALLY_REQUEST,
  };
}

export const RESET_PASSWORD_FINALLY_SUCCESS = 'RESET_PASSWORD_FINALLY_SUCCESS';
export function resetPasswordFinallySuccess() {
  return {
    type: RESET_PASSWORD_FINALLY_SUCCESS,
  };
}

export const RESET_PASSWORD_FINALLY_FAILURE = 'RESET_PASSWORD_FINALLY_FAILURE';
export function resetPasswordFinallyFailure() {
  return {
    type: RESET_PASSWORD_FINALLY_FAILURE,
  };
}

export const STORE_CHECKOUT_DATA = 'STORE_CHECKOUT_DATA';
export function storeCheckoutData(data) {
  return {
    type: STORE_CHECKOUT_DATA,
    data,
  };
}

export const STORE_CHECKOUT_DATA_REQUEST = 'STORE_CHECKOUT_DATA_REQUEST';
export function storeCheckoutDataRequest(data) {
  return {
    type: STORE_CHECKOUT_DATA_REQUEST,
    data,
  };
}

export const PRICING = 'PRICING';
export function fetchPricing(data) {
  return {
    type: PRICING,
    data,
  };
}

export const PRICING_REQUEST = 'PRICING_REQUEST';
export function fetchPricingRequest() {
  return {
    type: PRICING_REQUEST,
  };
}
export const PRICING_SUCCESS = 'PRICING_SUCCESS';
export function fetchPricingSuccess(data) {
  return {
    type: PRICING_SUCCESS,
    data,
  };
}
export const PRICING_FAILURE = 'PRICING_FAILURE';
export function fetchPricingFailure() {
  return {
    type: PRICING_FAILURE,
  };
}

export const LOADING_EVENT = 'LOADING_EVENT';
export function loadingEvent() {
  return {
    type: LOADING_EVENT,
  };
}

export const FINISHED_EVENT = 'FINISHED_EVENT';
export function finishedEvent() {
  return {
    type: FINISHED_EVENT,
  };
}

export const GET_EVENT = 'GET_EVENT';
export function getEvent(eventId, accessToken) {
  return {
    type: GET_EVENT,
    accessToken,
    eventId,
  };
}

export const GET_EVENT_REQUEST = 'GET_EVENT_REQUEST';
export function getEventRequest() {
  return {
    type: GET_EVENT_REQUEST,
  };
}

export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export function getEventSuccess(data) {
  return {
    type: GET_EVENT_SUCCESS,
    data,
  };
}

export const GET_EVENT_FAILURE = 'GET_EVENT_FAILURE';
export function getEventFailure(error) {
  return {
    type: GET_EVENT_FAILURE,
    error,
  };
}

export const CREATE_EVENT = 'CREATE_EVENT';
export function createEvent(data, accessToken) {
  return {
    type: CREATE_EVENT,
    accessToken,
    data,
  };
}

export const CREATE_EVENT_REQUEST = 'CREATE_EVENT_REQUEST';
export function createEventRequest() {
  return {
    type: CREATE_EVENT_REQUEST,
  };
}

export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export function createEventSuccess(data) {
  return {
    type: CREATE_EVENT_SUCCESS,
    data,
  };
}

export const CREATE_EVENT_FAILURE = 'CREATE_EVENT_FAILURE';
export function createEventFailure(error) {
  return {
    type: CREATE_EVENT_FAILURE,
    error,
  };
}

export const UPDATE_EVENT = 'UPDATE_EVENT';
export function updateEvent(id, data, accessToken) {
  return {
    type: UPDATE_EVENT,
    accessToken,
    data,
    id,
  };
}

export const UPDATE_EVENT_REQUEST = 'UPDATE_EVENT_REQUEST';
export function updateEventRequest() {
  return {
    type: UPDATE_EVENT_REQUEST,
  };
}

export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export function updateEventSuccess(data) {
  toast.success('Event saved.', { autoClose: toastDismissTime });
  return {
    type: UPDATE_EVENT_SUCCESS,
    data,
  };
}

export const UPDATE_EVENT_FAILURE = 'UPDATE_EVENT_FAILURE';
export function updateEventFailure(error) {
  toast.error(error, { autoClose: false });
  return {
    type: UPDATE_EVENT_FAILURE,
    error,
  };
}

export const DUPLICATE_EVENT = 'DUPLICATE_EVENT';
export function duplicateEvent(id, accessToken) {
  return {
    type: DUPLICATE_EVENT,
    accessToken,
    id,
  };
}

export const DUPLICATE_EVENT_REQUEST = 'DUPLICATE_EVENT_REQUEST';
export function duplicateEventRequest() {
  return {
    type: DUPLICATE_EVENT_REQUEST,
  };
}

export const DUPLICATE_EVENT_SUCCESS = 'DUPLICATE_EVENT_SUCCESS';
export function duplicateEventSuccess(data) {
  return {
    type: DUPLICATE_EVENT_SUCCESS,
    data,
  };
}

export const DUPLICATE_EVENT_FAILURE = 'DUPLICATE_EVENT_FAILURE';
export function duplicateEventFailure(error) {
  return {
    type: DUPLICATE_EVENT_FAILURE,
    error,
  };
}

export const DELETE_EVENTS = 'DELETE_EVENTS';
export function deleteEvents(ids, accessToken) {
  let eventIds = ids;
  if (!Array.isArray(eventIds)) {
    eventIds = [eventIds];
  }

  return {
    type: DELETE_EVENTS,
    accessToken,
    ids: eventIds,
  };
}

export const DELETE_EVENTS_REQUEST = 'DELETE_EVENTS_REQUEST';
export function deleteEventsRequest() {
  return {
    type: DELETE_EVENTS_REQUEST,
  };
}

export const DELETE_EVENTS_SUCCESS = 'DELETE_EVENTS_SUCCESS';
export function deleteEventsSuccess() {
  return {
    type: DELETE_EVENTS_SUCCESS,
  };
}

export const DELETE_EVENTS_FAILURE = 'DELETE_EVENTS_FAILURE';
export function deleteEventsFailure(errors) {
  return {
    type: DELETE_EVENTS_FAILURE,
    errors,
  };
}

export const LIST_EVENTS = 'LIST_EVENTS';
export function listEvents(data, accessToken, level = 1) {
  return {
    type: LIST_EVENTS,
    data,
    accessToken,
    level,
  };
}

export const LIST_EVENTS_RESET = 'LIST_EVENTS_RESET';
export function listEventsReset() {
  return {
    type: LIST_EVENTS_RESET,
  };
}

export const LIST_EVENTS_REQUEST = 'LIST_EVENTS_REQUEST';
export function listEventsRequest() {
  return {
    type: LIST_EVENTS_REQUEST,
  };
}

export const LIST_EVENTS_SUCCESS = 'LIST_EVENTS_SUCCESS';
export function listEventsSuccess(data) {
  return {
    type: LIST_EVENTS_SUCCESS,
    data,
  };
}

export const LIST_EVENTS_FAILURE = 'LIST_EVENTS_FAILURE';
export function listEventsFailure(error) {
  return {
    type: LIST_EVENTS_FAILURE,
    error,
  };
}

export const LIST_SAMPLE_EVENTS = 'LIST_SAMPLE_EVENTS';
export function listSampleEvents(accessToken, level = 1) {
  return {
    type: LIST_SAMPLE_EVENTS,
    accessToken,
    level,
  };
}

export const LIST_SAMPLE_EVENTS_REQUEST = 'LIST_SAMPLE_EVENTS_REQUEST';
export function listSampleEventsRequest() {
  return {
    type: LIST_SAMPLE_EVENTS_REQUEST,
  };
}

export const LIST_SAMPLE_EVENTS_SUCCESS = 'LIST_SAMPLE_EVENTS_SUCCESS';
export function listSampleEventsSuccess(data) {
  return {
    type: LIST_SAMPLE_EVENTS_SUCCESS,
    data,
  };
}

export const LIST_SAMPLE_EVENTS_FAILURE = 'LIST_SAMPLE_EVENTS_FAILURE';
export function listSampleEventsFailure(error) {
  return {
    type: LIST_SAMPLE_EVENTS_FAILURE,
    error,
  };
}

export const LIST_MEDIAS = 'LIST_MEDIAS';
export function listMedias(eventId, data, accessToken) {
  return {
    type: LIST_MEDIAS,
    eventId,
    data,
    accessToken,
  };
}

export const LIST_MEDIAS_RESET = 'LIST_MEDIAS_RESET';
export function listMediasReset() {
  return {
    type: LIST_MEDIAS_RESET,
  };
}

export const LIST_MEDIAS_REQUEST = 'LIST_MEDIAS_REQUEST';
export function listMediasRequest() {
  return {
    type: LIST_MEDIAS_REQUEST,
  };
}

export const LIST_MEDIAS_SUCCESS = 'LIST_MEDIAS_SUCCESS';
export function listMediasSuccess(data) {
  return {
    type: LIST_MEDIAS_SUCCESS,
    data,
  };
}

export const LIST_MEDIAS_FAILURE = 'LIST_MEDIAS_FAILURE';
export function listMediasFailure(error) {
  return {
    type: LIST_MEDIAS_FAILURE,
    error,
  };
}

export const DELETE_MEDIAS = 'DELETE_MEDIAS';
export function deleteMedias(eventId, ids, accessToken) {
  let mediaIds = ids;
  if (!Array.isArray(mediaIds)) {
    mediaIds = [mediaIds];
  }

  return {
    type: DELETE_MEDIAS,
    accessToken,
    eventId,
    ids: mediaIds,
  };
}

export const DELETE_MEDIAS_REQUEST = 'DELETE_MEDIAS_REQUEST';
export function deleteMediasRequest() {
  return {
    type: DELETE_MEDIAS_REQUEST,
  };
}

export const DELETE_MEDIAS_SUCCESS = 'DELETE_MEDIAS_SUCCESS';
export function deleteMediasSuccess() {
  return {
    type: DELETE_MEDIAS_SUCCESS,
  };
}

export const DELETE_MEDIAS_FAILURE = 'DELETE_MEDIAS_FAILURE';
export function deleteMediasFailure(error) {
  return {
    type: DELETE_MEDIAS_FAILURE,
    error,
  };
}

export const EMAIL_LINKS = 'EMAIL_LINKS';
export function emailLinks(eventId, data, accessToken) {
  return {
    type: EMAIL_LINKS,
    eventId,
    data,
    accessToken,
  };
}

export const EMAIL_LINKS_REQUEST = 'EMAIL_LINKS_REQUEST';
export function emailLinksRequest() {
  return {
    type: EMAIL_LINKS_REQUEST,
  };
}

export const EMAIL_LINKS_SUCCESS = 'EMAIL_LINKS_SUCCESS';
export function emailLinksSuccess() {
  // toast.success('Email sent successfully.', { autoClose: toastDismissTime })
  return {
    type: EMAIL_LINKS_SUCCESS,
  };
}

export const EMAIL_LINKS_FAILURE = 'EMAIL_LINKS_FAILURE';
export function emailLinksFailure(error) {
  toast.error(error, { autoClose: false });
  return {
    type: EMAIL_LINKS_FAILURE,
    error,
  };
}

export const EMAIL_CANCELLATION = 'EMAIL_CANCELLATION';
export function emailCancellation(data, accessToken) {
  return {
    type: EMAIL_CANCELLATION,
    data,
    accessToken,
  };
}

export const EMAIL_CANCELLATION_REQUEST = 'EMAIL_CANCELLATION_REQUEST';
export function emailCancellationRequest() {
  return {
    type: EMAIL_CANCELLATION_REQUEST,
  };
}

export const EMAIL_CANCELLATION_SUCCESS = 'EMAIL_CANCELLATION_SUCCESS';
export function emailCancellationSuccess() {
  // toast.success('Your cancellation request was submitted and will be processed shortly.', { autoClose: 6000 })
  return {
    type: EMAIL_CANCELLATION_SUCCESS,
  };
}

export const EMAIL_CANCELLATION_FAILURE = 'EMAIL_CANCELLATION_FAILURE';
export function emailCancellationFailure(error) {
  toast.error(error, { autoClose: false });
  return {
    type: EMAIL_CANCELLATION_FAILURE,
    error,
  };
}

export const ADD_SENDING_DOMAIN = 'ADD_SENDING_DOMAIN';
export function addSendingDomain(domain, accessToken) {
  return {
    type: ADD_SENDING_DOMAIN,
    domain,
    accessToken,
  };
}

export const ADD_SENDING_DOMAIN_REQUEST = 'ADD_SENDING_DOMAIN_REQUEST';
export function addSendingDomainRequest() {
  return {
    type: ADD_SENDING_DOMAIN_REQUEST,
  };
}

export const ADD_SENDING_DOMAIN_SUCCESS = 'ADD_SENDING_DOMAIN_SUCCESS';
export function addSendingDomainSuccess(data) {
  return {
    type: ADD_SENDING_DOMAIN_SUCCESS,
    data,
  };
}

export const ADD_SENDING_DOMAIN_FAILURE = 'ADD_SENDING_DOMAIN_FAILURE';
export function addSendingDomainFailure(error) {
  return {
    type: ADD_SENDING_DOMAIN_FAILURE,
    error,
  };
}

export const DELETE_SENDING_DOMAIN = 'DELETE_SENDING_DOMAIN';
export function deleteSendingDomain(accessToken) {
  return {
    type: DELETE_SENDING_DOMAIN,
    accessToken,
  };
}

export const DELETE_SENDING_DOMAIN_REQUEST = 'DELETE_SENDING_DOMAIN_REQUEST';
export function deleteSendingDomainRequest() {
  return {
    type: DELETE_SENDING_DOMAIN_REQUEST,
  };
}

export const DELETE_SENDING_DOMAIN_SUCCESS = 'DELETE_SENDING_DOMAIN_SUCCESS';
export function deleteSendingDomainSuccess() {
  return {
    type: DELETE_SENDING_DOMAIN_SUCCESS,
  };
}

export const DELETE_SENDING_DOMAIN_FAILURE = 'DELETE_SENDING_DOMAIN_FAILURE';
export function deleteSendingDomainFailure(error) {
  return {
    type: DELETE_SENDING_DOMAIN_FAILURE,
    error,
  };
}

export const UPDATE_OPERATOR = 'UPDATE_OPERATOR';
export function updateOperator(data, accessToken) {
  return {
    type: UPDATE_OPERATOR,
    data,
    accessToken,
  };
}

export const UPDATE_OPERATOR_REQUEST = 'UPDATE_OPERATOR_REQUEST';
export function updateOperatorRequest() {
  return {
    type: UPDATE_OPERATOR_REQUEST,
  };
}

export const UPDATE_OPERATOR_SUCCESS = 'UPDATE_OPERATOR_SUCCESS';
export function updateOperatorSuccess(data) {
  toast.success('Account updated.', { autoClose: toastDismissTime });
  return {
    type: UPDATE_OPERATOR_SUCCESS,
    data,
  };
}

export const UPDATE_OPERATOR_FAILURE = 'UPDATE_OPERATOR_FAILURE';
export function updateOperatorFailure(error) {
  toast.error(error, { autoClose: false });
  return {
    type: UPDATE_OPERATOR_FAILURE,
    error,
  };
}

export const UPDATE_ENVIRONMENT = 'UPDATE_ENVIRONMENT';
export function updateEnvironment(environment) {
  return {
    type: UPDATE_ENVIRONMENT,
    environment,
  };
}

export const SET_BACKEND_URL = 'SET_BACKEND_URL';
export function setBackendUrl() {
  return {
    type: SET_BACKEND_URL,
  };
}

export const SET_OPERATOR = 'SET_OPERATOR';
export function setOperator(operator) {
  return {
    type: SET_OPERATOR,
    operator,
  };
}

export const UPDATE_ACADEMY_VIDEO_PROGRESS = 'UPDATE_ACADEMY_VIDEO_PROGRESS';
export function updateAcademyVideoProgress(vimeoId, currentViewPercent, accessToken) {
  return {
    type: UPDATE_ACADEMY_VIDEO_PROGRESS,
    vimeoId,
    currentViewPercent,
    accessToken,
  };
}

export const UPDATE_ACADEMY_VIDEO_PROGRESS_REQUEST = 'UPDATE_ACADEMY_VIDEO_PROGRESS_REQUEST';
export function updateAcademyVideoProgressRequest() {
  return {
    type: UPDATE_ACADEMY_VIDEO_PROGRESS_REQUEST,
  };
}

export const UPDATE_ACADEMY_VIDEO_PROGRESS_SUCCESS = 'UPDATE_ACADEMY_VIDEO_PROGRESS_SUCCESS';
export function updateAcademyVideoProgressSuccess(data) {
  return {
    type: UPDATE_ACADEMY_VIDEO_PROGRESS_SUCCESS,
    data,
  };
}

export const UPDATE_ACADEMY_VIDEO_PROGRESS_FAILURE = 'UPDATE_ACADEMY_VIDEO_PROGRESS_FAILURE';
export function updateAcademyVideoProgressFailure(error) {
  return {
    type: UPDATE_ACADEMY_VIDEO_PROGRESS_FAILURE,
    error,
  };
}

export const SET_EVENT_VIEW = 'SET_EVENT_VIEW';
export function setEventView(data) {
  return {
    type: SET_EVENT_VIEW,
    data,
  };
}

export const GET_SSE_EVENT = 'GET_SSE_EVENT';
export function getSseEvent(eventId, accessToken) {
  return {
    type: GET_SSE_EVENT,
    accessToken,
    eventId,
  };
}

export const GET_SSE_EVENT_REQUEST = 'GET_SSE_EVENT_REQUEST';
export function getSseEventRequest() {
  return {
    type: GET_SSE_EVENT_REQUEST,
  };
}

export const GET_SSE_EVENT_SUCCESS = 'GET_SSE_EVENT_SUCCESS';
export function getSseEventSuccess(data) {
  return {
    type: GET_SSE_EVENT_SUCCESS,
    data,
  };
}

export const GET_SSE_EVENT_FAILURE = 'GET_SSE_EVENT_FAILURE';
export function getSseEventFailure(error) {
  return {
    type: GET_SSE_EVENT_FAILURE,
    error,
  };
}

export const SET_EVENT_DATA = 'SET_EVENT_DATA';
export function setEventData(data) {
  return {
    type: SET_EVENT_DATA,
    data,
  };
}

export const SET_PROFILE_VIEW = 'SET_PROFILE_VIEW';
export function setProfileView(data) {
  return {
    type: SET_PROFILE_VIEW,
    data,
  };
}

export const GET_SSE_PROFILE = 'GET_SSE_PROFILE';
export function getSseProfile(organizationId, accessToken) {
  return {
    type: GET_SSE_PROFILE,
    organizationId,
    accessToken,
  };
}

export const GET_SSE_PROFILE_REQUEST = 'GET_SSE_PROFILE_REQUEST';
export function getSseProfileRequest() {
  return {
    type: GET_SSE_PROFILE_REQUEST,
  };
}

export const GET_SSE_PROFILE_SUCCESS = 'GET_SSE_PROFILE_SUCCESS';
export function getSseProfileSuccess(data) {
  return {
    type: GET_SSE_PROFILE_SUCCESS,
    data,
  };
}

export const GET_SSE_PROFILE_FAILURE = 'GET_SSE_PROFILE_FAILURE';
export function getSseProfileFailure() {
  return {
    type: GET_SSE_PROFILE_FAILURE,
  };
}

export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
export function setProfileData(data) {
  return {
    type: SET_PROFILE_DATA,
    data,
  };
}

/**
 * Asset library
 */

export const LIST_ASSETS = 'LIST_ASSETS';
export function listAssets(data, accessToken) {
  return {
    type: LIST_ASSETS,
    data,
    accessToken,
  };
}

export const LIST_ASSETS_RESET = 'LIST_ASSETS_RESET';
export function listAssetsReset() {
  return {
    type: LIST_ASSETS_RESET,
  };
}

export const LIST_ASSETS_REQUEST = 'LIST_ASSETS_REQUEST';
export function listAssetsRequest() {
  return {
    type: LIST_ASSETS_REQUEST,
  };
}

export const LIST_ASSETS_SUCCESS = 'LIST_ASSETS_SUCCESS';
export function listAssetsSuccess(data) {
  return {
    type: LIST_ASSETS_SUCCESS,
    data,
  };
}

export const LIST_ASSETS_FAILURE = 'LIST_ASSETS_FAILURE';
export function listAssetsFailure(error) {
  return {
    type: LIST_ASSETS_FAILURE,
    error,
  };
}

export const LOAD_SYS_ASSETS = 'LOAD_SYS_ASSETS';
export function loadSysAssets(category) {
  return {
    type: LOAD_SYS_ASSETS,
    category,
  };
}

export const LOAD_SYS_ASSETS_REQUEST = 'LOAD_SYS_ASSETS_REQUEST';
export function loadSysAssetsRequest() {
  return {
    type: LOAD_SYS_ASSETS_REQUEST,
  };
}

export const LOAD_SYS_ASSETS_SUCCESS = 'LOAD_SYS_ASSETS_SUCCESS';
export function loadSysAssetsSuccess(data) {
  return {
    type: LOAD_SYS_ASSETS_SUCCESS,
    data,
  };
}

export const LOAD_SYS_ASSETS_FAILURE = 'LOAD_SYS_ASSETS_FAILURE';
export function loadSysAssetsFailure(error) {
  return {
    type: LOAD_SYS_ASSETS_FAILURE,
    error,
  };
}

export const UPLOAD_ASSET = 'UPLOAD_ASSET';
export function uploadAsset(file, suffix, category, accessToken) {
  return {
    type: UPLOAD_ASSET,
    file,
    suffix,
    category,
    accessToken,
  };
}

export const UPLOAD_ASSET_REQUEST = 'UPLOAD_ASSET_REQUEST';
export function uploadAssetRequest() {
  return {
    type: UPLOAD_ASSET_REQUEST,
  };
}

export const UPLOAD_ASSET_SUCCESS = 'UPLOAD_ASSET_SUCCESS';
export function uploadAssetSuccess(data) {
  toast.success('Asset uploaded successfully.', { autoClose: toastDismissTime });
  return {
    type: UPLOAD_ASSET_SUCCESS,
    data,
  };
}

export const UPLOAD_ASSET_FAILURE = 'UPLOAD_ASSET_FAILURE';
export function uploadAssetFailure(error) {
  toast.error('There was an error uploading the asset.', { autoClose: false });
  return {
    type: UPLOAD_ASSET_FAILURE,
    error,
  };
}

export const DELETE_ASSETS = 'DELETE_ASSETS';
export function deleteAssets(ids, accessToken) {
  let assetIds = ids;
  if (!Array.isArray(assetIds)) {
    assetIds = [assetIds];
  }

  return {
    type: DELETE_ASSETS,
    accessToken,
    ids: assetIds,
  };
}

export const DELETE_ASSETS_REQUEST = 'DELETE_ASSETS_REQUEST';
export function deleteAssetsRequest() {
  return {
    type: DELETE_ASSETS_REQUEST,
  };
}

export const DELETE_ASSETS_SUCCESS = 'DELETE_ASSETS_SUCCESS';
export function deleteAssetsSuccess(data) {
  toast.success('Asset(s) deleted successfully.', { autoClose: toastDismissTime });
  return {
    type: DELETE_ASSETS_SUCCESS,
    data,
  };
}

export const DELETE_ASSETS_FAILURE = 'DELETE_ASSETS_FAILURE';
export function deleteAssetsFailure(errors) {
  toast.error('There was an error deleting your asset(s).', { autoClose: false });
  return {
    type: DELETE_ASSETS_FAILURE,
    errors,
  };
}

export const GET_PRINT_TEMPLATE = 'GET_PRINT_TEMPLATE';
export function getPrintTemplate(printTemplateId, accessToken) {
  return {
    type: GET_PRINT_TEMPLATE,
    printTemplateId,
    accessToken,
  };
}

export const GET_PRINT_TEMPLATE_REQUEST = 'GET_PRINT_TEMPLATE_REQUEST';
export function getPrintTemplateRequest() {
  return {
    type: GET_PRINT_TEMPLATE_REQUEST,
  };
}

export const GET_PRINT_TEMPLATE_SUCCESS = 'GET_PRINT_TEMPLATE_SUCCESS';
export function getPrintTemplateSuccess(data) {
  return {
    type: GET_PRINT_TEMPLATE_SUCCESS,
    data,
  };
}

export const GET_PRINT_TEMPLATE_FAILURE = 'GET_PRINT_TEMPLATE_FAILURE';
export function getPrintTemplateFailure(error) {
  return {
    type: GET_PRINT_TEMPLATE_FAILURE,
    error,
  };
}

export const DOWNLOAD_SYS_TEMPLATE = 'DOWNLOAD_SYS_TEMPLATE';
export function downloadSysTemplate(key, accessToken) {
  return {
    type: DOWNLOAD_SYS_TEMPLATE,
    key,
    accessToken,
  };
}

export const DOWNLOAD_SYS_TEMPLATE_REQUEST = 'DOWNLOAD_SYS_TEMPLATE_REQUEST';
export function downloadSysTemplateRequest() {
  return {
    type: DOWNLOAD_SYS_TEMPLATE_REQUEST,
  };
}

export const DOWNLOAD_SYS_TEMPLATE_SUCCESS = 'DOWNLOAD_SYS_TEMPLATE_SUCCESS';
export function downloadSysTemplateSuccess(data) {
  return {
    type: DOWNLOAD_SYS_TEMPLATE_SUCCESS,
    data,
  };
}

export const DOWNLOAD_SYS_TEMPLATE_FAILURE = 'DOWNLOAD_SYS_TEMPLATE_FAILURE';
export function downloadSysTemplateFailure(error) {
  return {
    type: DOWNLOAD_SYS_TEMPLATE_FAILURE,
    error,
  };
}

export const UPDATE_PRINT_TEMPLATE = 'UPDATE_PRINT_TEMPLATE';
export function updatePrintTemplate(printTemplateId, data, accessToken) {
  return {
    type: UPDATE_PRINT_TEMPLATE,
    printTemplateId,
    data,
    accessToken,
  };
}

export const UPDATE_PRINT_TEMPLATE_REQUEST = 'UPDATE_PRINT_TEMPLATE_REQUEST';
export function updatePrintTemplateRequest() {
  return {
    type: UPDATE_PRINT_TEMPLATE_REQUEST,
  };
}

export const UPDATE_PRINT_TEMPLATE_SUCCESS = 'UPDATE_PRINT_TEMPLATE_SUCCESS';
export function updatePrintTemplateSuccess(data) {
  return {
    type: UPDATE_PRINT_TEMPLATE_SUCCESS,
    data,
  };
}

export const UPDATE_PRINT_TEMPLATE_FAILURE = 'UPDATE_PRINT_TEMPLATE_FAILURE';
export function updatePrintTemplateFailure(error) {
  return {
    type: UPDATE_PRINT_TEMPLATE_FAILURE,
    error,
  };
}

export const CREATE_PRINT_TEMPLATE = 'CREATE_PRINT_TEMPLATE';
export function createPrintTemplate(eventLongId, data, accessToken) {
  return {
    type: CREATE_PRINT_TEMPLATE,
    eventLongId,
    data,
    accessToken,
  };
}

export const CREATE_PRINT_TEMPLATE_REQUEST = 'CREATE_PRINT_TEMPLATE_REQUEST';
export function createPrintTemplateRequest() {
  return {
    type: CREATE_PRINT_TEMPLATE_REQUEST,
  };
}

export const CREATE_PRINT_TEMPLATE_SUCCESS = 'CREATE_PRINT_TEMPLATE_SUCCESS';
export function createPrintTemplateSuccess(data) {
  return {
    type: CREATE_PRINT_TEMPLATE_SUCCESS,
    data,
  };
}

export const CREATE_PRINT_TEMPLATE_FAILURE = 'CREATE_PRINT_TEMPLATE_FAILURE';
export function createPrintTemplateFailure(error) {
  return {
    type: CREATE_PRINT_TEMPLATE_FAILURE,
    error,
  };
}

export const LIST_PRINT_TEMPLATES = 'LIST_PRINT_TEMPLATES';
export function listPrintTemplates(accessToken, data) {
  return {
    type: LIST_PRINT_TEMPLATES,
    accessToken,
    data,
  };
}

export const LIST_PRINT_TEMPLATES_REQUEST = 'LIST_PRINT_TEMPLATES_REQUEST';
export function listPrintTemplatesRequest() {
  return {
    type: LIST_PRINT_TEMPLATES_REQUEST,
  };
}

export const LIST_PRINT_TEMPLATES_SUCCESS = 'LIST_PRINT_TEMPLATES_SUCCESS';
export function listPrintTemplatesSuccess(data) {
  return {
    type: LIST_PRINT_TEMPLATES_SUCCESS,
    data,
  };
}

export const LIST_PRINT_TEMPLATES_FAILURE = 'LIST_PRINT_TEMPLATES_FAILURE';
export function listPrintTemplatesFailure(error) {
  return {
    type: LIST_PRINT_TEMPLATES_FAILURE,
    error,
  };
}

export const LIST_PRINT_TEMPLATES_RESET = 'LIST_PRINT_TEMPLATES_RESET';
export function listPrintTemplatesReset() {
  return {
    type: LIST_PRINT_TEMPLATES_RESET,
  };
}

export const DELETE_PRINT_TEMPLATES = 'DELETE_PRINT_TEMPLATES';
export function deletePrintTemplates(ids, accessToken) {
  return {
    type: DELETE_PRINT_TEMPLATES,
    ids,
    accessToken,
  };
}

export const DELETE_PRINT_TEMPLATES_REQUEST = 'DELETE_PRINT_TEMPLATES_REQUEST';
export function deletePrintTemplatesRequest() {
  return {
    type: DELETE_PRINT_TEMPLATES_REQUEST,
  };
}

export const DELETE_PRINT_TEMPLATES_SUCCESS = 'DELETE_PRINT_TEMPLATES_SUCCESS';
export function deletePrintTemplatesSuccess(ids) {
  toast.success('Template(s) deleted successfully.', { autoClose: toastDismissTime });
  return {
    type: DELETE_PRINT_TEMPLATES_SUCCESS,
    ids,
  };
}

export const DELETE_PRINT_TEMPLATES_FAILURE = 'DELETE_PRINT_TEMPLATES_FAILURE';
export function deletePrintTemplatesFailure(error) {
  toast.error('There was an error deleting your templates.', { autoClose: false });
  return {
    type: DELETE_PRINT_TEMPLATES_FAILURE,
    error,
  };
}

export const LIST_PRINT_TEMPLATE_LAYOUTS = 'LIST_PRINT_TEMPLATE_LAYOUTS';
export function listPrintTemplateLayouts(accessToken) {
  return {
    type: LIST_PRINT_TEMPLATE_LAYOUTS,
    accessToken,
  };
}

export const LIST_PRINT_TEMPLATE_LAYOUTS_REQUEST = 'LIST_PRINT_TEMPLATE_LAYOUTS_REQUEST';
export function listPrintTemplateLayoutsRequest() {
  return {
    type: LIST_PRINT_TEMPLATE_LAYOUTS_REQUEST,
  };
}

export const LIST_PRINT_TEMPLATE_LAYOUTS_SUCCESS = 'LIST_PRINT_TEMPLATE_LAYOUTS_SUCCESS';
export function listPrintTemplateLayoutsSuccess(data) {
  return {
    type: LIST_PRINT_TEMPLATE_LAYOUTS_SUCCESS,
    data,
  };
}

export const LIST_PRINT_TEMPLATE_LAYOUTS_FAILURE = 'LIST_PRINT_TEMPLATE_LAYOUTS_FAILURE';
export function listPrintTemplateLayoutsFailure(error) {
  return {
    type: LIST_PRINT_TEMPLATE_LAYOUTS_FAILURE,
    error,
  };
}

export const LIST_SYS_TEMPLATES = 'LIST_SYS_TEMPLATES';
export function listSysTemplates(templateType, accessToken) {
  return {
    type: LIST_SYS_TEMPLATES,
    templateType,
    accessToken,
  };
}

export const LIST_SYS_TEMPLATES_REQUEST = 'LIST_SYS_TEMPLATES_REQUEST';
export function listSysTemplatesRequest() {
  return {
    type: LIST_SYS_TEMPLATES_REQUEST,
  };
}

export const LIST_SYS_TEMPLATES_SUCCESS = 'LIST_SYS_TEMPLATES_SUCCESS';
export function listSysTemplatesSuccess(data) {
  return {
    type: LIST_SYS_TEMPLATES_SUCCESS,
    data,
  };
}

export const LIST_SYS_TEMPLATES_FAILURE = 'LIST_SYS_TEMPLATES_FAILURE';
export function listSysTemplatesFailure(error) {
  return {
    type: LIST_SYS_TEMPLATES_FAILURE,
    error,
  };
}

export const LIST_SYS_FONTS = 'LIST_SYS_FONTS';
export function listSysFonts(accessToken) {
  return {
    type: LIST_SYS_FONTS,
    accessToken,
  };
}

export const LIST_SYS_FONTS_REQUEST = 'LIST_SYS_FONTS_REQUEST';
export function listSysFontsRequest() {
  return {
    type: LIST_SYS_FONTS_REQUEST,
  };
}

export const LIST_SYS_FONTS_SUCCESS = 'LIST_SYS_FONTS_SUCCESS';
export function listSysFontsSuccess(data) {
  return {
    type: LIST_SYS_FONTS_SUCCESS,
    data,
  };
}

export const LIST_SYS_FONTS_FAILURE = 'LIST_SYS_FONTS_FAILURE';
export function listSysFontsFailure(error) {
  return {
    type: LIST_SYS_FONTS_FAILURE,
    error,
  };
}

export const LIST_POSE_TIPS = 'LIST_POSE_TIPS';
export function listPoseTips(operatorId) {
  return {
    type: LIST_POSE_TIPS,
    operatorId,
  };
}

export const LIST_POSE_TIPS_REQUEST = 'LIST_POSE_TIPS_REQUEST';
export function listPoseTipsRequest() {
  return {
    type: LIST_POSE_TIPS_REQUEST,
  };
}

export const LIST_POSE_TIPS_SUCCESS = 'LIST_POSE_TIPS_SUCCESS';
export function listPoseTipsSuccess(data) {
  return {
    type: LIST_POSE_TIPS_SUCCESS,
    data,
  };
}

export const LIST_POSE_TIPS_FAILURE = 'LIST_POSE_TIPS_FAILURE';
export function listPoseTipsFailure(error) {
  return {
    type: LIST_POSE_TIPS_FAILURE,
    error,
  };
}

export const GET_APP_USER_STATS = 'GET_APP_USER_STATS';
export function getAppUserStats(accessToken) {
  return {
    type: GET_APP_USER_STATS,
    accessToken,
  };
}

export const GET_APP_USER_STATS_REQUEST = 'GET_APP_USER_STATS_REQUEST';
export function getAppUserStatsRequest() {
  return {
    type: GET_APP_USER_STATS_REQUEST,
  };
}

export const GET_APP_USER_STATS_SUCCESS = 'GET_APP_USER_STATS_SUCCESS';
export function getAppUserStatsSuccess(data) {
  return {
    type: GET_APP_USER_STATS_SUCCESS,
    data,
  };
}

export const GET_APP_USER_STATS_FAILURE = 'GET_APP_USER_STATS_FAILURE';
export function getAppUserStatsFailure(error) {
  return {
    type: GET_APP_USER_STATS_FAILURE,
    error,
  };
}

export const UPDATE_USER_PLAN = 'UPDATE_USER_PLAN';
export function updateUserPlan(data, accessToken) {
  return {
    type: UPDATE_USER_PLAN,
    data,
    accessToken,
  };
}

export const UPDATE_USER_PLAN_REQUEST = 'UPDATE_USER_PLAN_REQUEST';
export function updateUserPlanRequest() {
  return {
    type: UPDATE_USER_PLAN_REQUEST,
  };
}

export const UPDATE_USER_PLAN_SUCCESS = 'UPDATE_USER_PLAN_SUCCESS';
export function updateUserPlanSuccess(data) {
  return {
    type: UPDATE_USER_PLAN_SUCCESS,
    data,
  };
}

export const UPDATE_USER_PLAN_FAILURE = 'UPDATE_USER_PLAN_FAILURE';
export function updateUserPlanFailure(error) {
  return {
    type: UPDATE_USER_PLAN_FAILURE,
    error,
  };
}

export const CANCEL_PLAN_SCHEDULE = 'CANCEL_PLAN_SCHEDULE';
export function cancelPlanSchedule(accessToken) {
  return {
    type: CANCEL_PLAN_SCHEDULE,
    accessToken,
  };
}

export const CANCEL_PLAN_SCHEDULE_REQUEST = 'CANCEL_PLAN_SCHEDULE_REQUEST';
export function cancelPlanScheduleRequest() {
  return {
    type: CANCEL_PLAN_SCHEDULE_REQUEST,
  };
}

export const CANCEL_PLAN_SCHEDULE_SUCCESS = 'CANCEL_PLAN_SCHEDULE_SUCCESS';
export function cancelPlanScheduleSuccess(data) {
  return {
    type: CANCEL_PLAN_SCHEDULE_SUCCESS,
    data,
  };
}

export const CANCEL_PLAN_SCHEDULE_FAILURE = 'CANCEL_PLAN_SCHEDULE_FAILURE';
export function cancelPlanScheduleFailure(error) {
  return {
    type: CANCEL_PLAN_SCHEDULE_FAILURE,
    error,
  };
}

export const SUBSCRIBE = 'SUBSCRIBE';
export function subscribe(data, accessToken) {
  return {
    type: SUBSCRIBE,
    data,
    accessToken,
  };
}

export const SUBSCRIBE_REQUEST = 'SUBSCRIBE_REQUEST';
export function subscribeRequest() {
  return {
    type: SUBSCRIBE_REQUEST,
  };
}

export const SUBSCRIBE_SUCCESS = 'SUBSCRIBE_SUCCESS';
export function subscribeSuccess(data) {
  return {
    type: SUBSCRIBE_SUCCESS,
    data,
  };
}

export const SUBSCRIBE_FAILURE = 'SUBSCRIBE_FAILURE';
export function subscribeFailure(error) {
  return {
    type: SUBSCRIBE_FAILURE,
    error,
  };
}

export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export function verifyEmail(token) {
  return {
    type: VERIFY_EMAIL,
    token,
  };
}

export const VERIFY_EMAIL_REQUEST = 'VERIFY_EMAIL_REQUEST';
export function verifyEmailRequest() {
  return {
    type: VERIFY_EMAIL_REQUEST,
  };
}

export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export function verifyEmailSuccess(data) {
  return {
    type: VERIFY_EMAIL_SUCCESS,
    data,
  };
}

export const VERIFY_EMAIL_FAILURE = 'VERIFY_EMAIL_FAILURE';
export function verifyEmailFailure(error) {
  return {
    type: VERIFY_EMAIL_FAILURE,
    error,
  };
}

export const RESEND_VERIFICATION = 'RESEND_VERIFICATION';
export function resendVerification(email) {
  return {
    type: RESEND_VERIFICATION,
    email,
  };
}

export const RESEND_VERIFICATION_REQUEST = 'RESEND_VERIFICATION_REQUEST';
export function resendVerificationRequest() {
  return {
    type: RESEND_VERIFICATION_REQUEST,
  };
}

export const RESEND_VERIFICATION_SUCCESS = 'RESEND_VERIFICATION_SUCCESS';
export function resendVerificationSuccess(data) {
  return {
    type: RESEND_VERIFICATION_SUCCESS,
    data,
  };
}

export const RESEND_VERIFICATION_FAILURE = 'RESEND_VERIFICATION_FAILURE';
export function resendVerificationFailure(error) {
  return {
    type: RESEND_VERIFICATION_FAILURE,
    error,
  };
}

export const CHECK_FOR_MEDIA = 'CHECK_FOR_MEDIA';
export function checkForMedia(sourceId) {
  return {
    type: CHECK_FOR_MEDIA,
    sourceId,
  };
}

export const CHECK_FOR_MEDIA_REQUEST = 'CHECK_FOR_MEDIA_REQUEST';
export function checkForMediaRequest() {
  return {
    type: CHECK_FOR_MEDIA_REQUEST,
  };
}

export const CHECK_FOR_MEDIA_SUCCESS = 'CHECK_FOR_MEDIA_SUCCESS';
export function checkForMediaSuccess(data) {
  return {
    type: CHECK_FOR_MEDIA_SUCCESS,
    data,
  };
}

export const CHECK_FOR_MEDIA_FAILURE = 'CHECK_FOR_MEDIA_FAILURE';
export function checkForMediaFailure(error) {
  return {
    type: CHECK_FOR_MEDIA_FAILURE,
    error,
  };
}

export const SUBMIT_PENDING_SHARE = 'SUBMIT_PENDING_SHARE';
export function submitPendingShare(sourceId, target, shareType) {
  return {
    type: SUBMIT_PENDING_SHARE,
    sourceId,
    target,
    shareType,
  };
}

export const SUBMIT_PENDING_SHARE_REQUEST = 'SUBMIT_PENDING_SHARE_REQUEST';
export function submitPendingShareRequest() {
  return {
    type: SUBMIT_PENDING_SHARE_REQUEST,
  };
}

export const SUBMIT_PENDING_SHARE_SUCCESS = 'SUBMIT_PENDING_SHARE_SUCCESS';
export function submitPendingShareSuccess(data) {
  return {
    type: SUBMIT_PENDING_SHARE_SUCCESS,
    data,
  };
}

export const SUBMIT_PENDING_SHARE_FAILURE = 'SUBMIT_PENDING_SHARE_FAILURE';
export function submitPendingShareFailure(data) {
  return {
    type: SUBMIT_PENDING_SHARE_FAILURE,
    data,
  };
}

export const UPLOAD_MEDIA = 'UPLOAD_MEDIA';
export function uploadMedia(source, eventId, captureMode, frameRate) {
  return {
    type: UPLOAD_MEDIA,
    source,
    eventId,
    captureMode,
    frameRate,
  };
}

export const UPLOAD_MEDIA_REQUEST = 'UPLOAD_MEDIA_REQUEST';
export function uploadMediaRequest() {
  return {
    type: UPLOAD_MEDIA_REQUEST,
  };
}

export const UPLOAD_MEDIA_SUCCESS = 'UPLOAD_MEDIA_SUCCESS';
export function uploadMediaSuccess(data) {
  return {
    type: UPLOAD_MEDIA_SUCCESS,
    data,
  };
}

export const UPLOAD_MEDIA_FAILURE = 'UPLOAD_MEDIA_FAILURE';
export function uploadMediaFailure(error) {
  return {
    type: UPLOAD_MEDIA_FAILURE,
    error,
  };
}

export const PROCESS_MEDIA = 'PROCESS_MEDIA';
export function processMedia(source, eventId, settings) {
  return {
    type: PROCESS_MEDIA,
    source,
    eventId,
    settings,
  };
}

export const PROCESS_MEDIA_REQUEST = 'PROCESS_MEDIA_REQUEST';
export function processMediaRequest() {
  return {
    type: PROCESS_MEDIA_REQUEST,
  };
}

export const PROCESS_MEDIA_SUCCESS = 'PROCESS_MEDIA_SUCCESS';
export function processMediaSuccess(data) {
  return {
    type: PROCESS_MEDIA_SUCCESS,
    data,
  };
}

export const PROCESS_MEDIA_FAILURE = 'PROCESS_MEDIA_FAILURE';
export function processMediaFailure(error) {
  return {
    type: PROCESS_MEDIA_FAILURE,
    error,
  };
}

export const VIRTUAL_BOOTH = 'VIRTUAL_BOOTH';
export function virtualBooth(eventId) {
  return {
    type: VIRTUAL_BOOTH,
    eventId,
  };
}

export const VIRTUAL_BOOTH_REQUEST = 'VIRTUAL_BOOTH_REQUEST';
export function virtualBoothRequest() {
  return {
    type: VIRTUAL_BOOTH_REQUEST,
  };
}

export const VIRTUAL_BOOTH_SUCCESS = 'VIRTUAL_BOOTH_SUCCESS';
export function virtualBoothSuccess(data) {
  return {
    type: VIRTUAL_BOOTH_SUCCESS,
    data,
  };
}

export const VIRTUAL_BOOTH_FAILURE = 'VIRTUAL_BOOTH_FAILURE';
export function virtualBoothFailure(error) {
  return {
    type: VIRTUAL_BOOTH_FAILURE,
    error,
  };
}

/**
 * Upload capture to S3 and add it to the database
 */

export const UPLOAD_CAPTURE_TO_S3 = 'UPLOAD_CAPTURE_TO_S3';
export function uploadCaptureToS3(eventId, file, captureMode, accessToken) {
  return {
    type: UPLOAD_CAPTURE_TO_S3,
    eventId,
    file,
    captureMode,
    accessToken,
  };
}

export const UPLOAD_CAPTURE_TO_S3_REQUEST = 'UPLOAD_CAPTURE_TO_S3_REQUEST';
export function uploadCaptureToS3Request() {
  return {
    type: UPLOAD_CAPTURE_TO_S3_REQUEST,
  };
}

export const UPLOAD_CAPTURE_TO_S3_SUCCESS = 'UPLOAD_CAPTURE_TO_S3_SUCCESS';
export function uploadCaptureToS3Success(data) {
  return {
    type: UPLOAD_CAPTURE_TO_S3_SUCCESS,
    data,
  };
}

export const UPLOAD_CAPTURE_TO_S3_FAILURE = 'UPLOAD_CAPTURE_TO_S3_FAILURE';
export function uploadCaptureToS3Failure(error) {
  return {
    type: UPLOAD_CAPTURE_TO_S3_FAILURE,
    error,
  };
}

export const SUPERUSER_LOGIN = 'SUPERUSER_LOGIN';
export function superUserLogin(email, password) {
  return {
    type: SUPERUSER_LOGIN,
    email,
    password,
  };
}

export const SUPERUSER_LOGIN_REQUEST = 'SUPERUSER_LOGIN_REQUEST';
export function superUserLoginRequest() {
  return {
    type: SUPERUSER_LOGIN_REQUEST,
  };
}

export const SUPERUSER_LOGIN_SUCCESS = 'SUPERUSER_LOGIN_SUCCESS';
export function superUserLoginSuccess(data) {
  return {
    type: SUPERUSER_LOGIN_SUCCESS,
    data,
  };
}

export const SUPERUSER_LOGIN_FAILURE = 'SUPERUSER_LOGIN_FAILURE';
export function superUserLoginFailure(error) {
  return {
    type: SUPERUSER_LOGIN_FAILURE,
    error,
  };
}

export const SUPERUSER_GET_ACCESS = 'SUPERUSER_GET_ACCESS';
export function superUserGetAccess(email, accessToken) {
  return {
    type: SUPERUSER_GET_ACCESS,
    email,
    accessToken,
  };
}

export const SUPERUSER_GET_ACCESS_REQUEST = 'SUPERUSER_GET_ACCESS_REQUEST';
export function superUserGetAccessRequest() {
  return {
    type: SUPERUSER_GET_ACCESS_REQUEST,
  };
}

export const SUPERUSER_GET_ACCESS_SUCCESS = 'SUPERUSER_GET_ACCESS_SUCCESS';
export function superUserGetAccessSuccess(data) {
  return {
    type: SUPERUSER_GET_ACCESS_SUCCESS,
    data,
  };
}

export const SUPERUSER_GET_ACCESS_FAILURE = 'SUPERUSER_GET_ACCESS_FAILURE';
export function superUserGetAccessFailure(error) {
  return {
    type: SUPERUSER_GET_ACCESS_FAILURE,
    error,
  };
}

export const SUPERUSER_GET_OPERATOR_TOKEN = 'SUPERUSER_GET_OPERATOR_TOKEN';
export function superUserGetOperatorToken(email, loginToken) {
  return {
    type: SUPERUSER_GET_OPERATOR_TOKEN,
    email,
    loginToken,
  };
}

export const SUPERUSER_GET_OPERATOR_TOKEN_REQUEST = 'SUPERUSER_GET_OPERATOR_TOKEN_REQUEST';
export function superUserGetOperatorTokenRequest() {
  return {
    type: SUPERUSER_GET_OPERATOR_TOKEN_REQUEST,
  };
}

export const SUPERUSER_GET_OPERATOR_TOKEN_SUCCESS = 'SUPERUSER_GET_OPERATOR_TOKEN_SUCCESS';
export function superUserGetOperatorTokenSuccess(data) {
  return {
    type: SUPERUSER_GET_OPERATOR_TOKEN_SUCCESS,
    data,
  };
}

export const SUPERUSER_GET_OPERATOR_TOKEN_FAILURE = 'SUPERUSER_GET_OPERATOR_TOKEN_FAILURE';
export function superUserGetOperatorTokenFailure(error) {
  return {
    type: SUPERUSER_GET_OPERATOR_TOKEN_FAILURE,
    error,
  };
}

export const SUPERUSER_GET_OPERATOR = 'SUPERUSER_GET_OPERATOR';
export function superUserGetOperator(email, accessToken) {
  return {
    type: SUPERUSER_GET_OPERATOR,
    email,
    accessToken,
  };
}

export const SUPERUSER_GET_OPERATOR_REQUEST = 'SUPERUSER_GET_OPERATOR_REQUEST';
export function superUserGetOperatorRequest() {
  return {
    type: SUPERUSER_GET_OPERATOR_REQUEST,
  };
}

export const SUPERUSER_GET_OPERATOR_SUCCESS = 'SUPERUSER_GET_OPERATOR_SUCCESS';
export function superUserGetOperatorSuccess(data) {
  return {
    type: SUPERUSER_GET_OPERATOR_SUCCESS,
    data,
  };
}

export const SUPERUSER_GET_OPERATOR_FAILURE = 'SUPERUSER_GET_OPERATOR_FAILURE';
export function superUserGetOperatorFailure(error) {
  return {
    type: SUPERUSER_GET_OPERATOR_FAILURE,
    error,
  };
}

export const SUPERUSER_UPDATE_OPERATOR = 'SUPERUSER_UPDATE_OPERATOR';
export function superUserUpdateOperator(operatorId, body, accessToken) {
  return {
    type: SUPERUSER_UPDATE_OPERATOR,
    operatorId,
    body,
    accessToken,
  };
}

export const SUPERUSER_UPDATE_OPERATOR_REQUEST = 'SUPERUSER_UPDATE_OPERATOR_REQUEST';
export function superUserUpdateOperatorRequest() {
  return {
    type: SUPERUSER_UPDATE_OPERATOR_REQUEST,
  };
}

export const SUPERUSER_UPDATE_OPERATOR_SUCCESS = 'SUPERUSER_UPDATE_OPERATOR_SUCCESS';
export function superUserUpdateOperatorSuccess(data) {
  toast.success('User updated successfully.', { autoClose: toastDismissTime });
  return {
    type: SUPERUSER_UPDATE_OPERATOR_SUCCESS,
    data,
  };
}

export const SUPERUSER_UPDATE_OPERATOR_FAILURE = 'SUPERUSER_UPDATE_OPERATOR_FAILURE';
export function superUserUpdateOperatorFailure(error) {
  toast.error('There was an error updating the user.', { autoClose: false });
  return {
    type: SUPERUSER_UPDATE_OPERATOR_FAILURE,
    error,
  };
}
